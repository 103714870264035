<template>
  <v-dialog v-model="dialog" :max-width="'90vw'" persistent>
    <div class="scanner-card">
      <div class="video-container">
        <!-- Title Chip (Appears Only If Title is Provided) -->
        <v-chip v-if="title" class="title-chip" color="primary" dark>
          {{ title }}
        </v-chip>

        <video ref="video" class="video-feed" autoplay></video>

        <!-- Overlay Buttons (Bottom Center) -->
        <div class="overlay-buttons">
          <v-btn icon class="glass-button" @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            v-if="devices.length > 1"
            icon
            class="glass-button"
            @click="switchCamera"
          >
            <v-icon>mdi-camera-switch</v-icon>
          </v-btn>
        </div>

        <!-- Error Message -->
        <v-alert v-if="errorMessage" type="error" dense class="error-alert">
          {{ errorMessage }}
        </v-alert>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { BrowserMultiFormatReader } from '@zxing/library';

export default {
  props: {
    value: Boolean, // Used for v-model in parent
    decodeParser: {
      type: Function,
      default: (value) => value,
    },
    title: {
      type: String,
      default: '', // Optional title
    },
  },
  data() {
    return {
      dialog: this.value,
      codeReader: new BrowserMultiFormatReader(),
      selectedDeviceId: null,
      devices: [],
      errorMessage: '',
    };
  },
  watch: {
    value(newValue) {
      this.dialog = newValue;
      if (newValue) {
        this.getCameras();
      } else {
        this.stopScanning();
      }
    },
  },
  beforeDestroy() {
    this.stopScanning();
  },
  methods: {
    async getCameras() {
      try {
        // Step 1: Request camera access (prioritize rear)
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'environment' },
        });

        // Step 2: List all available video input devices
        this.devices = await this.codeReader.listVideoInputDevices();

        if (this.devices.length > 0) {
          // Get active deviceId from the granted stream
          const track = stream.getVideoTracks()[0];
          const activeDeviceId = track.getSettings().deviceId;

          // Check if labels exist
          const hasLabels = this.devices.some(
            (d) => d.label && d.label.trim() !== ''
          );

          // Rear camera keywords for devices that provide labels
          const rearCameraKeywords = ['back', 'rear', 'environment'];

          // Try to find the rear camera:
          let rearCamera = this.devices.find(
            (d) =>
              hasLabels &&
              d.label &&
              rearCameraKeywords.some((keyword) =>
                d.label.toLowerCase().includes(keyword)
              )
          );

          // If no label, fallback to `deviceId` matching
          if (!rearCamera) {
            rearCamera = this.devices.find(
              (d) => d.deviceId === activeDeviceId
            );
          }

          // Step 3: Select camera
          this.selectedDeviceId =
            rearCamera?.deviceId || this.devices[0].deviceId;

          // Stop the temporary stream to free resources
          stream.getTracks().forEach((track) => track.stop());

          await this.startScanning();
        } else {
          this.errorMessage = 'No camera devices found.';
        }
      } catch (error) {
        this.errorMessage = 'Error accessing cameras: ' + error.message;
      }
    },

    async startScanning() {
      try {
        this.errorMessage = '';
        await this.codeReader.decodeFromVideoDevice(
          this.selectedDeviceId,
          this.$refs.video,
          (result) => {
            if (result) {
              let decodedValue;
              try {
                decodedValue = this.decodeParser
                  ? this.decodeParser(result.text)
                  : result.text;
                this.$emit('decoded', decodedValue);
                this.closeDialog();
              } catch (parseError) {
                this.errorMessage = 'Invalid QR Code format.';
                console.error('Parsing error:', parseError);
              }
            }
          }
        );
      } catch (error) {
        this.errorMessage = 'Error scanning QR code: ' + error.message;
      }
    },
    async switchCamera() {
      try {
        // Find the index of the current camera
        const currentIndex = this.devices.findIndex(
          (d) => d.deviceId === this.selectedDeviceId
        );

        // Select the next camera in the list (loop back if at the end)
        const nextIndex = (currentIndex + 1) % this.devices.length;
        this.selectedDeviceId = this.devices[nextIndex].deviceId;

        // Restart scanning with the new camera (without closing the dialog)
        this.codeReader.reset(); // Reset the scanner before changing the device
        await this.startScanning();
      } catch (error) {
        this.errorMessage = 'Error switching cameras: ' + error.message;
      }
    },

    closeDialog() {
      this.dialog = false;
      this.$emit('input', false);
      this.stopScanning();
    },
    stopScanning() {
      this.codeReader.reset();
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
/* Container to make sure video is centered and doesn't scroll */
.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  overflow: hidden;
}

/* Video should always fit the width while maintaining aspect ratio */
.video-feed {
  width: 100%;
  height: auto;
  max-height: 90vh;
  object-fit: contain;
}

/* Title chip positioned at the top center */
.title-chip {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
  z-index: 10;
}

/* Center the buttons at the bottom */
.overlay-buttons {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 15px;
}

/* Glass-like button effect */
.glass-button {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 50%;
  color: white;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.glass-button:hover {
  background: rgba(255, 255, 255, 0.3);
}

/* Error message styling */
.error-alert {
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  text-align: center;
}
</style>
